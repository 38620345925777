import bapenda from "../../../../../../../assets/image/new/Presensi Bapenda/megawe.png";
import POSAstra from "../../../../../../../assets/image/new/Dimitri/pos_dimitri.png";
import amazingRiyadhoh from "../../../../../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh.png";
import eoaEvents from "../../../../../../../assets/image/new/EOA Events/eoa_events.png";
import tvri from "../../../../../../../assets/image/new/TVRI/tvri.png";
import salamKurban from "../../../../../../../assets/image/new/Salam Kurban/salam_kurban.png";
import POSBeelife from "../../../../../../../assets/image/new/Beelife Indonesia/beelife_indonesia.png";
import goldScanner from "../../../../../../../assets/image/new/EOA Gold Scanner/eoa_gold_scanner.png";
import eoaWater from "../../../../../../../assets/image/new/EOA Water/eoa_water.png";
import POSGold from "../../../../../../../assets/image/new/POS Gold/pos_gold.png";

import bapendaLogo from "../../../../../../../assets/image/LogoProject/Bapenda.svg";
import ARLogo from "../../../../../../../assets/image/ar-logo.png";
import dimitriLogo from "../../../../../../../assets/image/LogoProject/Astra.svg";
import eventLogo from "../../../../../../../assets/image/LogoProject/EOA Events.svg";
import beelifeLogo from "../../../../../../../assets/image/LogoProject/Beelife Indonesia.svg";
import scannerLogo from "../../../../../../../assets/image/LogoProject/EOA Gold Scanner.svg";
import waterLogo from "../../../../../../../assets/image/LogoProject/EOA Water.svg";
import goldLogo from "../../../../../../../assets/image/LogoProject/EOA Gold.svg";
import tvriLogo from "../../../../../../../assets/image/LogoProject/TVRI.svg";
import qurbanLogo from "../../../../../../../assets/image/LogoProject/Salam Kurban.svg";

import androidIcon from "../../../../../../../assets/image/Platform/Play Store.svg";
import iOsIcon from "../../../../../../../assets/image/Platform/Appstore.svg";
import webIcon from "../../../../../../../assets/image/Platform/Web Browser.svg";

export const data = [
  {
    image: bapenda,
    platform: [androidIcon, iOsIcon, webIcon],
    logo: bapendaLogo,
    title: "Megawe Bapenda",
    description: "Presence System for Bapenda Employees",
    url: "/portofolio/Megawe Bapenda",
  },
  {
    image: POSAstra,
    platform: [androidIcon],
    logo: dimitriLogo,
    title: "POS DIMITRI Astra",
    description: " Point of Sales from Astra International",
    url: "/portofolio/POS DIMITRI Astra",
  },
  {
    image: amazingRiyadhoh,
    platform: [androidIcon, iOsIcon, webIcon],
    logo: ARLogo,
    title: "Amazing Riyadhoh",
    description: "Best apps to upgrade your worships",
    url: "/portofolio/Amazing Riyadhoh",
  },
  {
    image: eoaEvents,
    platform: [webIcon],
    logo: eventLogo,
    title: "EOA Events",
    description: "E-Ticketing for Event Organizer PPA Institute",
    url: "/portofolio/EOA Events",
  },
  {
    image: tvri,
    platform: [webIcon],
    logo: tvriLogo,
    title: "TVRI",
    description: "TVRI Company Profile",
    url: "/portofolio/TVRI",
  },
  {
    image: salamKurban,
    platform: [androidIcon, webIcon],
    logo: qurbanLogo,
    title: "Salam Kurban",
    description: "Selling Qurban more easier with Salam Kurban",
    url: "/portofolio/Salam Kurban",
  },
  {
    image: POSBeelife,
    platform: [webIcon],
    logo: beelifeLogo,
    title: "Berkah Natura Internasional",
    description: "Point of Sales from Beelife Indonesia",
    url: "/portofolio/Berkah Natura Internasional",
  },
  {
    image: goldScanner,
    platform: [androidIcon, webIcon],
    logo: scannerLogo,
    title: "EOA Gold Scanner",
    description: "The best way for gold packaging validity",
    url: "/portofolio/EOA Gold Scanner",
  },
  {
    image: eoaWater,
    platform: [webIcon],
    logo: waterLogo,
    title: "EOA Water",
    description: "EOA Water Company Profile",
    url: "/portofolio/EOA Water",
  },
  {
    image: POSGold,
    platform: [webIcon],
    logo: goldLogo,
    title: "POS EOA GOLD",
    description: "Point of Sales from EOA GOLD",
    url: "/portofolio/POS EOA GOLD",
  },
];
