import React, { Fragment, useState } from "react";
import {
  AppBar,
  Button,
  Box,
  Divider,
  Icon,
  IconButton,
  Slide,
  Toolbar,
  useColorScheme,
  useScrollTrigger,
  List,
  ListItem,
  ListItemButton,
  // ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";

import IGIcon from "../../../assets/icon/ig.png";
import FBIcon from "../../../assets/icon/wa.svg";
import INIcon from "../../../assets/icon/in.png";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import Logo from "../../../assets/image/Logo.svg";
import { Menu } from "@mui/icons-material";

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const ModeSwitcher = () => {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    // for server-side rendering
    // learn more at https://github.com/pacocoursey/next-themes#avoid-hydration-mismatch
    return null;
  }

  return (
    <IconButton
      sx={{
        color: "#238AE4",
        borderRadius: "10px",
        border: (thm) => `1px solid ${thm.palette.primary.main}`,
        padding: "3px",
      }}
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
    >
      {mode === "light" ? (
        <LightModeOutlinedIcon fontSize={"small"} />
      ) : (
        <DarkModeOutlinedIcon fontSize={"small"} />
      )}
    </IconButton>
  );
};

const AppBarComponent = ({ navigate, props }) => {
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key={"left"} disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"left"} disablePadding>
          <ListItemButton onClick={() => navigate("/services")}>
            <ListItemText primary={"Service"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"left"} disablePadding>
          <ListItemButton onClick={() => navigate("/portofolio")}>
            <ListItemText primary={"Portofolio"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"left"} disablePadding>
          <ListItemButton onClick={() => navigate("/about-us")}>
            <ListItemText primary={"About"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"left"} disablePadding>
          <ListItemButton onClick={() => navigate("/contact-us")}>
            <ListItemText primary={"Contact Us"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"left"} disablePadding>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
              pl: "16px",
              height: "42px",
            }}
          >
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://instagram.com/krealogi_inovasidigital?igshid=Y2IzZGU1MTFhOQ=="
              target="_blank"
            >
              <Icon>
                <img src={IGIcon} alt={"ig-icon"} />
              </Icon>
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://wa.me/+6281290326850"
              target="_blank"
            >
              <Icon>
                <img
                  src={FBIcon}
                  alt={"fb-icon"}
                  style={{ marginTop: "5px", width: "24px" }}
                />
              </Icon>
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://www.linkedin.com/company/krealogi-inovasi-digital/"
              target="_blank"
            >
              <Icon>
                <img src={INIcon} alt={"linkIN-icon"} />
              </Icon>
            </IconButton>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <Fragment>
      <HideOnScroll {...props}>
        <AppBar>
          <Box
            sx={{
              display: { lg: "flex", xs: "none" },
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "8px 120px",
              gap: 3,
              height: "42px",
            }}
          >
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://instagram.com/krealogi_inovasidigital?igshid=Y2IzZGU1MTFhOQ=="
              target="_blank"
            >
              <Icon>
                <img src={IGIcon} alt={"ig-icon"} />
              </Icon>
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://wa.me/+6281290326850"
              target="_blank"
            >
              <Icon>
                <img
                  src={FBIcon}
                  alt={"fb-icon"}
                  style={{ marginTop: "5px", width: "24px" }}
                />
              </Icon>
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
              }}
              href="https://www.linkedin.com/company/krealogi-inovasi-digital/"
              target="_blank"
            >
              <Icon>
                <img src={INIcon} alt={"linkIN-icon"} />
              </Icon>
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              color={"#E0E0E0"}
            />
            <ModeSwitcher />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: { xs: "8px", lg: "8px 120px" },
              gap: "24px",
              height: "64px",
            }}
          >
            <img src={Logo} alt={"logo"} />
            <Box
              sx={{
                display: { lg: "flex", xs: "none" },
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
                gap: "50px",
                height: "64px",
                textTransform: "none",
              }}
            >
              <Button color="inherit" onClick={() => navigate("/")}>
                Home
              </Button>
              <Button color="inherit" onClick={() => navigate("/services")}>
                Services
              </Button>
              <Button color="inherit" onClick={() => navigate("/portofolio")}>
                Portfolio
              </Button>
              <Button color="inherit" onClick={() => navigate("/about-us")}>
                About
              </Button>
              <Button color="inherit" onClick={() => navigate("/contact-us")}>
                Contact
              </Button>
            </Box>
            <Box
              component={"div"}
              sx={{
                display: { lg: "none", sx: "inherit" },
              }}
            >
              <ModeSwitcher />
              <Button onClick={toggleDrawer("left", true)}>
                <Menu />
              </Button>
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
              >
                {list("left")}
              </Drawer>
            </Box>
          </Box>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </Fragment>
  );
};

export default AppBarComponent;
