import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import tvri from "../../../../assets/image/Client/TVRI.png";
import astra from "../../../../assets/image/Client/ASTRA.png";
import bapenda from "../../../../assets/image/Client/BAPENDA.png";
import ngampooz from "../../../../assets/image/Client/NGAMPOOZ.png";
import peduli from "../../../../assets/image/Client/PEDULI.png";
import pupr from "../../../../assets/image/Client/PUPR.png";
import tempo from "../../../../assets/image/Client/TEMPO.png";
import adi from "../../../../assets/image/Client/ADI.png";
import eoaTech from "../../../../assets/image/Client/EOA-TECH.png";
import eoaClub from "../../../../assets/image/Client/EOA-CLUB.png";
import client from "../../../../assets/image/Client/CLIENT.png";
import salamQurban from "../../../../assets/image/Client/SALAM-KURBAN.png";
import ppa from "../../../../assets/image/Client/PPA.png";

import { styled } from "@mui/system";

const FlexItem = styled(Box)(({ theme }) => ({
  flexBasis: "120px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Description = () => {
  return (
    <Stack pl={2} pr={2} mb={16} mt={16} gap={2}>
      <Stack alignItems={"center"} textAlign={"center"}>
        <Typography
          variant={"h3"}
          fontWeight={900}
          fontSize={48}
          lineHeight={"56px"}
          color={"var(--mui-palette-primary-main)"}
        >
          We develop web apps, manage events and <br />
          procurements
        </Typography>
        <Typography
          variant={"h6"}
          fontSize={20}
          fontWeight={400}
          lineHeight={"32px"}
          letterSpacing={"0.15px"}
        >
          Krealogi Inovasi Digital will make your product look modern and
          professional while saving you precious time.
        </Typography>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: 5,
          justifyContent: "center",
          margin: "0px 45px",
        }}
      >
        <FlexItem>
          <img
            src={tvri}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={astra}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={bapenda}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={ngampooz}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={peduli}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={pupr}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={tempo}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img src={adi} alt={"client"} style={{ filter: "grayscale(100%)" }} />
        </FlexItem>
        <FlexItem>
          <img
            src={eoaTech}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={eoaClub}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={client}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img
            src={salamQurban}
            alt={"client"}
            style={{ filter: "grayscale(100%)" }}
          />
        </FlexItem>
        <FlexItem>
          <img src={ppa} alt={"client"} style={{ filter: "grayscale(100%)" }} />
        </FlexItem>
      </Box>
    </Stack>
  );
};

export default Description;
