import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import team from "../../../assets/image/team2.png";
import { OurStory, WeAre } from "./components";
import { Clients, ContactUs, OurTeam } from "../Home/homeComponents";
import { useColor } from "../../../shared/utility";

const AboutUs = () => {
  const color = useColor();

  return (
    <Fragment>
      <Box
        sx={{
          background: `url(${team})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "450px",
        }}
      >
        <Box
          sx={{
            pl: { lg: "120px", xs: 2 },
            pr: { lg: "120px", xs: 2 },
            position: "absolute",
            top: { lg: "350px", xs: 220 },
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant={"h3"}
            fontWeight={600}
            fontSize={48}
            lineHeight={"56px"}
            color={"#FFFFFF"}
          >
            About us
          </Typography>
          <Typography
            variant={"h6"}
            fontSize={20}
            fontWeight={200}
            lineHeight={"32px"}
            letterSpacing={"0.15px"}
            color={"#FFFFFF"}
          >
            We take you by hand on each step of the process <tr /> As experts in
            both design & development, we help you go through the complete
            process. <tr />
            From your new website idea, to design, development, launch and
            scale!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          pl: { lg: "120px", xs: 2 },
          pr: { lg: "120px", xs: 2 },
          mt: "30px",
          mb: "30px",
        }}
      >
        <OurStory />
      </Box>
      <Box
        sx={{
          pl: { lg: "120px", xs: 2 },
          pr: { lg: "120px", xs: 2 },
          mt: "30px",
          mb: "30px",
        }}
      >
        <WeAre />
      </Box>
      {/* <Box>
        <OurTeam />
      </Box> */}
      <Box>
        <Clients color={color} />
      </Box>
      <Box
        sx={{
          pl: { lg: "120px", xs: 2 },
          pr: { lg: "120px", xs: 2 },
          mb: "30px",
        }}
      >
        <ContactUs />
      </Box>
    </Fragment>
  );
};

export default AboutUs;
