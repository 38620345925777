import React from "react";
import { Box, styled } from "@mui/system";
import { Typography } from "@mui/material";

import tvri from "../../../../assets/image/Client/TVRI.png";
import astra from "../../../../assets/image/Client/ASTRA.png";
import bapenda from "../../../../assets/image/Client/BAPENDA.png";
import ngampooz from "../../../../assets/image/Client/NGAMPOOZ.png";
import peduli from "../../../../assets/image/Client/PEDULI.png";
import pupr from "../../../../assets/image/Client/PUPR.png";
import tempo from "../../../../assets/image/Client/TEMPO.png";
import adi from "../../../../assets/image/Client/ADI.png";
import eoaTech from "../../../../assets/image/Client/EOA-TECH.png";
import eoaClub from "../../../../assets/image/Client/EOA-CLUB.png";
import client from "../../../../assets/image/Client/CLIENT.png";
import salamQurban from "../../../../assets/image/Client/SALAM-KURBAN.png";
import ppa from "../../../../assets/image/Client/PPA.png";

const FlexItem = styled(Box)(({ theme }) => ({
  flexBasis: "120px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Clients = ({ color }) => {
  return (
    <Box
      sx={{
        pt: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "800px",
        gap: 9,
        pl: { lg: "120px", xs: 2 },
        pr: { lg: "120px", xs: 2 },
        mt: "30px",
        mb: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant={"h3"}
          color={color.primary}
          fontWeight={600}
          lineHeight={"56px"}
        >
          Our Happy Clients
        </Typography>
        <Typography
          variant={"body1"}
          color={color.textSecondary}
          fontWeight={300}
        >
          Krealogi Inovasi Digital will make your product look modern and
          professional while saving you precious time.
        </Typography>
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            justifyContent: "center",
          }}
        >
          <FlexItem>
            <img
              src={tvri}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={astra}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={bapenda}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={ngampooz}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={peduli}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={pupr}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={tempo}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={adi}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={eoaTech}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={eoaClub}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={client}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={salamQurban}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
          <FlexItem>
            <img
              src={ppa}
              alt={"client"}
              style={{ filter: "grayscale(100%)" }}
            />
          </FlexItem>
        </Box>
      </Box>
    </Box>
  );
};

export default Clients;
