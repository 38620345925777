import bapendaLogo from "../../../assets/image/LogoProject/Bapenda.svg";
import ARLogo from "../../../assets/image/ar-logo.png";
import dimitriLogo from "../../../assets/image/LogoProject/Astra.svg";
import eventLogo from "../../../assets/image/LogoProject/PPA Institute.svg";
import beelifeLogo from "../../../assets/image/LogoProject/Beelife Indonesia.svg";
import scannerLogo from "../../../assets/image/LogoProject/EOA Gold Scanner.svg";
import waterLogo from "../../../assets/image/LogoProject/EOA Water.svg";
import goldLogo from "../../../assets/image/LogoProject/EOA Gold.svg";
import salamKurbanLogo from "../../../assets/image/LogoProject/Salam Kurban.svg";
import tvriLogo from "../../../assets/image/LogoProject/TVRI.svg";

import bapenda1 from "../../../assets/image/new/Presensi Bapenda/megawe.png";
import bapenda2 from "../../../assets/image/new/Presensi Bapenda/megawe1.png";
import bapenda3 from "../../../assets/image/new/Presensi Bapenda/megawe2.png";
import dimitri1 from "../../../assets/image/new/Dimitri/pos_dimitri.png";
import dimitri2 from "../../../assets/image/new/Dimitri/pos_dimitri1.png";
import dimitri3 from "../../../assets/image/new/Dimitri/pos_dimitri2.png";

import porto1 from "../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh.png";
import porto2 from "../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh1.png";
import porto3 from "../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh2.png";
import event1 from "../../../assets/image/new/EOA Events/eoa_events.png";
import event2 from "../../../assets/image/new/EOA Events/eoa_events1.png";
import event3 from "../../../assets/image/new/EOA Events/eoa_events2.png";
import beelife1 from "../../../assets/image/new/Beelife Indonesia/beelife_indonesia.png";
import beelife2 from "../../../assets/image/new/Beelife Indonesia/beelife_indonesia1.png";
import beelife3 from "../../../assets/image/new/Beelife Indonesia/beelife_indonesia2.png";
import scanner1 from "../../../assets/image/new/EOA Gold Scanner/eoa_gold_scanner.png";
import scanner2 from "../../../assets/image/new/EOA Gold Scanner/eoa_gold_scanner1.png";
import scanner3 from "../../../assets/image/new/EOA Gold Scanner/eoa_gold_scanner2.png";
import water1 from "../../../assets/image/new/EOA Water/eoa_water.png";
import water2 from "../../../assets/image/new/EOA Water/eoa_water1.png";
import water3 from "../../../assets/image/new/EOA Water/eoa_water2.png";
import gold1 from "../../../assets/image/new/POS Gold/pos_gold.png";
import gold2 from "../../../assets/image/new/POS Gold/pos_gold1.png";
import gold3 from "../../../assets/image/new/POS Gold/pos_gold2.png";
import salam_kurban from "../../../assets/image/new/Salam Kurban/salam_kurban.png";
import salam_kurban1 from "../../../assets/image/new/Salam Kurban/salam_kurban1.png";
import salam_kurban2 from "../../../assets/image/new/Salam Kurban/salam_kurban2.png";
import tvri1 from "../../../assets/image/new/TVRI/tvri.png";
import tvri2 from "../../../assets/image/new/TVRI/tvri1.png";
import tvri3 from "../../../assets/image/new/TVRI/tvri2.png";

import appleIcon from "../../../assets/image/portfolio/AR/Vector.png";
import dnsIcon from "../../../assets/image/portfolio/AR/dns.png";
import andoridIcon from "../../../assets/image/portfolio/AR/Group.png";
import webIcon from "../../../assets/image/portfolio/AR/Vector (1).png";

import web from "../../../assets/image/Platform/Web Client.svg";
import appStore from "../../../assets/image/Platform/App Store.svg";
import playStore from "../../../assets/image/Platform/Google Play.svg";

export const data = [
  {
    category: "IT/Software Development",
    logo: bapendaLogo,
    title: "Megawe Bapenda",
    subtitle: [
      "Presence System for Bapenda Employees",
      "Presence employees with Geolocation",
      "Target Overview of Revenue",
    ],
    description: [
      "Megawe Bapenda adalah aplikasi untuk memonitoring kinerja pegawai\n" +
        "Badan Pendapatan Daerah Kota Serang secara real time menggunakan\n" +
        "titik koordinat lokasi. Aplikasi ini juga untuk memantau pegawai yang\n" +
        "hadir di kantor, pegawai yang sedang ke lapangan, pegawai yang sedang\n" +
        "izin, pegawai yang sedang sakit dan pegawai yang sedang melakukan\n" +
        "perjalanan dinas.",
      "Fitur presensi yang terintegrasi dengan lokasi device yang dapat mengetahui lokasi saat melakukan presensi. Ditambah dengan fitur selfie realtime yang dibutuhkan pada saat melakukan presensi membuat sistem presensi lebih valid.",
      "Target Overview merupakan fitur tambahan dari sistem presensi ini, yaitu pencatatan progres dari target yang dibuat oleh admin yang dapat dilihat oleh semua karyawan dengan perhitungan persentase.",
    ],
    platform: [playStore],
    image: [bapenda1, bapenda2, bapenda3],
    technology: [andoridIcon, dnsIcon],
    deliverables: [
      "Manage Presence Employees",
      "Geolocation",
      "Target Overview of Revenue",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: dimitriLogo,
    title: "POS DIMITRI Astra",
    subtitle: [
      "Point of Sales from Astra International",
      "Point of Sales System",
      "Manage Product, Customer and Supplier",
    ],
    description: [
      "Dimitri Astra adalah aplikasi yang membantu Dealer dan Supplier spare\n" +
        "part untuk mempermudah dalam mengatur transaksi pembelian dan\n" +
        "penjualan pada produk, meliputi mengatur stok, transaksi pembelian dan\n" +
        "penjualan, hingga laporan penjualannya.",
      "Sistem penjualan sederhana untuk menjual berbagai macam jenis kendaraan. Membantu dealer dalam mengatur transaksi penjualan maupun pembelian dalam jumlah banyak yang melibatkan banyak dealer/supplier dalam transaksi penjualan maupun pembelian tersebut.",
      "Menambah, mengubah dan menghapus data produk/stok, pelanggan dan supplier dengan mudah dan cepat untuk dapat melakukan pembelian maupun penjualan. Riwayat transaksi yang dapat dilihat langsung setelah melakukan transaksi.",
    ],
    platform: [playStore],
    image: [dimitri1, dimitri2, dimitri3],
    technology: [andoridIcon, dnsIcon],
    deliverables: [
      "Point of Sales System",
      "Manage Product, Customer and Supplier",
      "Report Selling",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: ARLogo,
    title: "Amazing Riyadhoh",
    subtitle: [
      "Best apps to upgrade your worships",
      "Group Target Riyadhoh",
      "Forum Group",
    ],
    description: [
      "Amazing Riyadhoh adalah aplikasi muslim dengan banyak fitur bermanfaat yang akan membantu kamu melakukan evaluasi ibadah harian. Tidak cuma itu, dengan aplikasi Amazing Riyadhoh juga bisa meningkatkan produktivitas dan kapasitas diri, menambah wawasan dengan konten-konten islami, menambah teman di komunitas islami dan masih banyak manfaat lainnya yang bisa didapatkan.",
      "Memungkinkan pengguna bergabung dengan group untuk melakukan riyadhoh ibadah bersama-sama dalam mencapai target yang telah ditentukan sebelumnya seperti target ibadah sholat, membaca Al-Qur’an, berpuasa, ziswaf dan dzikir baik dalam hitungan keseluruhan maupun spesifik. Admin group dapat menambahkan beberapa anggota untuk menjadi admin untuk dapat membuat target-target riyadhoh ibadah bersama-sama dalam group tersebut.",
      "Fitur Forum Group memungkinkan pengguna admin untuk membuat postingan dengan beberapa kategori yang ada. Anggota pada group dapat melihat postingan untuk dapat saling berkomentar dan menyukai postingan.",
    ],
    platform: [playStore, appStore, web],
    image: [porto1, porto2, porto3],
    technology: [appleIcon, andoridIcon, webIcon, dnsIcon],
    deliverables: [
      "iOS & Android User App",
      "Android Driver App",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: eventLogo,
    title: "EOA Events",
    subtitle: [
      "E-Ticketing for Event Organizer PPA Institute",
      "E-Ticketing",
      "Payment Gateway",
    ],
    description: [
      "EOA Event merupakan platform berbasis web dan mobile yang menyediakan tiket untuk keperluan berbagai acara khususnya acara di peradaban EOA seperti Training Online/Offline, seminar dll.",
      "Fitur E-ticketing atau tiket elektronik dengan QR Code yang memudahkan untuk melakukan presensi hanya dengan scan QR Code. Dengan banyak jenis harga yang dapat disesuaikan diantaranya adalah harga normal, reseat, diskon, infaq dan gratis dengan pembagian harga yang dapat disesuaikan juga.",
      "Sistem pembayaran yang mudah dengan menggunakan payment gateway sehingga dapat melakukan pembayaran dengan berbagai macam jenis salah satunya adalah dengan pembayaran Virtual Account Bank maupun verifikasi manual.",
    ],
    platform: [web],
    image: [event1, event2, event3],
    technology: [webIcon, dnsIcon],
    deliverables: [
      "E-Ticketing Event",
      "Payment Gateway",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: salamKurbanLogo,
    title: "Salam Kurban",
    subtitle: [
      "Selling Qurban more easier with Salam Kurban",
      "Pembelian Hewan Qurban",
      "Admin Web Console",
    ],
    description: [
      "Salam Kurban merupakan aplikasi penjualan kurban yang memungkinkan pembeli dapat dengan mudah mencari hewan qurban seperti kambing, sapi maupun kerbau dengan berbagai macam pilihan dan varian hewan qurban yang tersedia pada aplikasi.",
      "Pengguna dimudahkan dalam mencari hewan qurban dengan aplikasi Salam Kurban. Dengan berbagai macam jenis dan harga hewan qurban dapat dipilih sesuai keinginan. Proses transaksi pembelian hewan qurban dimulai dari checkout, menunggu pembayaran, menunggu konfirmasi, pembayaran diterima atau ditolak, pengiriman, berhasil atau tidak berhasil.",
      "Manage proses transaksi pembelian hewan qurban dengan mudah, manage pengguna aplikasi Salam Kurban, dan setting whatsapp untuk notifikasi transaksi pembelian hewan qurban hingga image slider yang ada pada aplikasi Salam Kurban.",
    ],
    platform: [playStore, appStore],
    image: [salam_kurban, salam_kurban1, salam_kurban2],
    technology: [appleIcon, andoridIcon, webIcon, dnsIcon],
    deliverables: [
      "Salam Kurban Apps",
      "Sign In with Google & Apple ID",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: tvriLogo,
    title: "TVRI - PPID & JDIH",
    subtitle: [
      "Company Profile TVRI, PPID & JDIH",
      "Company Profile & 2 others",
      "Live Streaming",
    ],
    description: [
      "TVRI adalah media publik dengan integritas tinggi untuk menyuarakan berbagai nilai, budaya, dan keberagaman yang menjadi kebanggaan Indonesia melalui konten programnya yang berkualitas ke seluruh lapisan masyarakat hingga ke berbagai penjuru tempat.",
      "Tiga website yang berbeda dari TVRI yaitu Company Profile TVRI, TVRI JDIH dan TVRI PPID merupakan website redesign website yang lama/sebelumnya dari Stasiun Televisi Swasta TVRI atau Televisi Republik Indonesia.",
      "Fitur Live Streaming TV memudahkan untuk menonton/mengakses televisi channel TVRI dari berbagai daerah. Dengan pencarian program acara yang memudahkan untuk mencari program acara dari TVRI dengan cepat.",
    ],
    platform: [web],
    image: [tvri1, tvri2, tvri3],
    technology: [appleIcon, andoridIcon, webIcon, dnsIcon],
    deliverables: [
      "Company Profile",
      "Layanan Informasi (JDIH)",
      "Layanan PPID",
      "Streaming Klik TVRI",
    ],
    complexity: "medium",
  },
  {
    category: "IT/Software Development",
    logo: beelifeLogo,
    title: "Berkah Natura Internasional",
    subtitle: [
      "Point of Sales from Beelife Indonesia",
      "Point of Sales",
      "Product Management",
    ],
    description: [
      "Beelife Indonesia merupakan perusahaan yang bergerak dalam bidang produksi dan pemasaran produk-produk kesehatan dan kecantikan dengan kualitas premium untuk keluarga Indonesia. Dengan produk berkualitas dan program kemitraan yang kami hadirkan, kami berkomitmen untuk menjaga kesehatan dan meningkatkan kesejahteraan keluarga Indonesia. Sehat, Sakinah, Sejahtera bersama Beelife Indonesia",
      "Sistem penjualan obat herbal dengan beberapa fitur yang memudahkan untuk melakukan pembelian maupun penjualan serta pendaftaran untuk menjadi keagenan yang memungkinkan untuk dapat melakukan penjualan lebih meluas.\n" +
        "Terdapat juga fitur laporan seperti laporan penjualan dan pembelian yang dapat membantu melihat perhitungan laba.",
      "Lebih mudah mengelola produk-produk yang akan dijual, melakukan perubahan harga dan melihat laporan produk.",
    ],
    platform: [web],
    image: [beelife1, beelife2, beelife3],
    technology: [appleIcon, andoridIcon, webIcon, dnsIcon],
    deliverables: [
      "Distribution System",
      "Point of Sales System",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
  {
    category: "IT/Software Development",
    logo: scannerLogo,
    title: "EOA Gold Scanner",
    subtitle: ["EOA Gold Scanner", "QR Code Scanner", "Web Administrator"],
    description: [
      "EOA Gold Scanner merupakan aplikasi berbasis mobile android milik EOA Gold yang digunakan untuk mengecek keaslian produk dengan menampilkan data detail produk dengan cara Scan QR Code yang ada pada kemasan EOA Gold.",
      "Langkah mudah untuk mengecek keaslian produk EOA Gold hanya dengan melakukan scan QR code yang ada pada kemasan EOA Gold. Detail produk EOA Gold otomatis akan muncul seperti Nama Produk, Kode Produk dan yang lainnya.",
      "Kelola pengguna aplikasi EOA Gold Scanner pada web administrator. Admin dapat melihat pengguna yang melakukan scan produk EOA Gold, meng-upload dokumen untuk produk-produk EOA Gold dan mengelola pengguna yang menggunakan aplikasi EOA GOld Scanner.",
    ],
    platform: [playStore, web],
    image: [scanner1, scanner2, scanner3],
    technology: [andoridIcon, webIcon, dnsIcon],
    deliverables: [
      "Android User App",
      "Validity Scanner",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "medium",
  },
  {
    category: "IT/Software Development",
    logo: waterLogo,
    title: "EOA Water",
    subtitle: ["EOA Water Company Profile", "Beatiful UI", "Web Administrator"],
    description: [
      "EOA WATER adalah salah satu persembahan terbaik dari Peradaban EOA untuk negeri tercinta kita Indonesia. Di usia 76 tahun kemerdekaan Indonesia, Peradaban EOA berkomitmen dan berkotribusi untuk berbakti kepada Negara Kesatuan Republik Indonesia dalam bentuk Air Minum GRATIS.",
      "Tampilan web yang responsive memungkinkan untuk dapat diakses pada browser web laptop maupun web browser mobile. Dengan komposisi warna yang sesuai menciptakan keindahan pada tampilan web.",
      "Sistem CMS produk yang dinamis seperti Produk dan Berita memudahkan dalam menambah, mengedit maupun menghapus konten pada web sesuka hati. ",
    ],
    platform: [web],
    image: [water1, water2, water3],
    technology: [webIcon, dnsIcon],
    deliverables: [
      "Responsive Web App",
      "Beautiful UI",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "medium",
  },
  {
    category: "IT/Software Development",
    logo: goldLogo,
    title: "POS EOA GOLD",
    subtitle: [
      "Point of Sales EOA Gold",
      "Specific Product Price",
      "Reporting",
    ],
    description: [
      "Point of Sales EOA Gold merupakan aplikasi berbasis web dengan berbagai macam fitur seperti Penjualan, Pembelian, Produk, Stok, Harga, Tukar Gramasi dan yang lainnya. Dengan aplikasi ini proses jual beli EOA Gold atau Emas EOA menjadi lebih mudah, cepat dan efisien. Memiliki lebih dari 5 role pengguna seperti admin pusat, cabang, Agen, Member dll.",
      "Kelola harga emas yang memiliki berbagai macam jenis dan varian serta role yang berbeda-beda dengan cepat dan mudah. Harga diupdate setiap saat dengan laporan perubahan harga dan laporan-laporan lainnya yang menyesuaikan.",
      "Semua laporan yang dibutuhkan seperti laporan Penjualan, Pembelian, Stok, Harga, Tukar Gramasi dan yang lainnya memudahkan pengguna dalam membaca laporan. Laporan difilter dalam waktu harian dan bulanan, terlihat jelas laba rugi dari penambahan/pengurangan stok pada laporan transaksi.",
    ],
    platform: [web],
    image: [gold1, gold2, gold3],
    technology: [webIcon, dnsIcon],
    deliverables: [
      "Manage Product Price",
      "Agent of Gold System",
      "Admin Web Console",
      "Cloud Backend Systems",
    ],
    complexity: "hard",
  },
];
