import * as React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Stack } from "@mui/material";

import { SoftwareDevelopment } from "./components";

const Tab = styled(TabUnstyled)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #fff;
  outline: 1px solid ${theme.palette.primary.main};
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${theme.palette.primary.main};
    color: #fff;
  }

  &:focus {
    color: #fff;
    outline: 1px solid ${theme.palette.primary.dark};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main};
    color: #fff;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  })
`
);

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: transparent;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  align-content: space-between;
  `
);

const TabPortofolio = () => {
  return (
    <Stack pl={2} pr={2} mb={16}>
      <TabsUnstyled defaultValue={0}>
        <Stack>
          <TabsList>
            <Tab>IT/Software Development</Tab>
            <Tab>Event</Tab>
            <Tab>Procurement</Tab>
          </TabsList>

          <div>
            <TabPanel value={0}>
              <SoftwareDevelopment />
            </TabPanel>
            <TabPanel value={1}>Second page</TabPanel>
            <TabPanel value={2}>Third page</TabPanel>
          </div>
        </Stack>
      </TabsUnstyled>
    </Stack>
  );
};

export default TabPortofolio;
