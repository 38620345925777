import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ComproLayout from "./layout/Compro";
import {
  Home,
  Portofolio,
  Sarvices,
  AboutUs,
  PortofolioDetail,
  Contact,
  PrivacyPolicyInvent
} from "./components/view";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ComproLayout>
        <Home />
      </ComproLayout>
    )
  },
  {
    path: "/services",
    element: (
      <ComproLayout>
        <Sarvices />
      </ComproLayout>
    )
  },
  {
    path: "/portofolio",
    element: (
      <ComproLayout>
        <Portofolio />
      </ComproLayout>
    )
  },
  {
    path: "/portofolio/:id",
    element: (
      <ComproLayout>
        <PortofolioDetail />
      </ComproLayout>
    )
  },
  {
    path: "/about-us",
    element: (
      <ComproLayout>
        <AboutUs />
      </ComproLayout>
    )
  },
  {
    path: "/contact-us",
    element: (
      <ComproLayout>
        <Contact />
      </ComproLayout>
    )
  },
  {
    path: "/privacy_policy_invent",
    element: (
      <ComproLayout>
        <PrivacyPolicyInvent />
      </ComproLayout>
    )
  }
]);

export default router;
