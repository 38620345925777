import React from "react";
import { Box } from "@mui/system";
import { Button, Stack, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import team from "../../../../assets/image/team.png";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={8}
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
      alignItems={"center"}
      minHeight={700}
    >
      <Grid xs={12} lg={6}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: 300,
            flexBasis: "584px",
          }}
        >
          <Typography
            fontSize={16}
            color={(theme) => theme.palette.secondary.main}
          >
            ABOUT US
          </Typography>
          <Typography
            variant={"h5"}
            fontWeight={300}
            lineHeight={"30px"}
            letterSpacing={"0.25px"}
          >
            We are a small agency of talented designers & developers
          </Typography>
          <Typography
            variant={"body1"}
            color={(theme) => theme.palette.text.secondary}
          >
            Unlike teams from big agencies, we will treat your project as ours.
            We will walk you through our smooth and simple process.
          </Typography>

          <Button
            variant={"contained"}
            endIcon={<ArrowForward />}
            color={"secondary"}
            sx={{ mt: 3, width: 176 }}
            onClick={() => navigate("/about-us")}
          >
            See Our History
          </Button>
        </Box>
      </Grid>
      <Grid xs={12} lg={6}>
        <Stack>
          <img src={team} alt={"team"} style={{ aspectRatio: 2 / 1 }} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
