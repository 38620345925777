import React, { Fragment } from "react";
import { Box } from "@mui/system";
import {
  AboutUs,
  Banner,
  Clients,
  ContactUs,
  FeaturedWork,
  OurSevices,
  OurTeam,
} from "./homeComponents";
import { useColor } from "../../../shared/utility";
import { Stack } from "@mui/material";

const Home = () => {
  const color = useColor();

  return (
    <Fragment>
      <Box>
        <Banner color={color} />
      </Box>
      <Box>
        <FeaturedWork color={color} />
      </Box>
      <Box>
        <Clients color={color} />
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <OurSevices color={color} />
      </Box>
      <Box>
        <AboutUs />
      </Box>
      {/* <Box>
        <OurTeam />
      </Box> */}
      <Stack
        pt={20}
        pb={10}
        pr={{ sx: "16px", lg: "120px" }}
        pl={{ sx: "16px", lg: "120px" }}
      >
        <ContactUs />
      </Stack>
    </Fragment>
  );
};

export default Home;
