import React, { Fragment } from "react";
import { Box } from "@mui/system";
import { Button, Stack, Typography } from "@mui/material";

import { ColorContext } from "../../../Provider/ProviderTypes";
import hero from "../../../../assets/image/hero.png";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const color = React.useContext(ColorContext);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid
        container
        spacing={8}
        alignItems={"center"}
        pl={{ xs: 2, lg: "120px" }}
        pr={{ xs: 2, lg: "120px" }}
        minHeight={"818px"}
      >
        <Grid xs={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              lineHeight={"72px"}
              letterSpacing={"0.5px"}
              component="div"
            >
              Develop anything your{" "}
              <span style={{ color: "var(--mui-palette-primary-main)" }}>
                business needs.
              </span>
            </Typography>

            <Typography
              variant="body1"
              color={color.text.secondary}
              fontWeight={300}
              lineHeight={"24px"}
              letterSpacing={"0.15px"}
              component="div"
            >
              Krealogi Inovasi Digital will make your product look modern and
              professional while saving you precious time.
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Box>
                <Button variant="contained">Let's Talk</Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/portofolio")}
                >
                  View Our Portfolio
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack>
            <img src={hero} alt={"hero"} style={{ aspectRatio: 16 / 9 }} />
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Banner;
