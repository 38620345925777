import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const WeAre = () => {
  return (
    <Fragment>
      <Grid container spacing={8}>
        <Grid xs={12} lg={6}>
          <Typography
            variant={"h4"}
            fontSize={34}
            lineHeight={"42px"}
            fontWeight={300}
          >
            Who We Are?
          </Typography>
          <Typography
            variant={"h6"}
            fontSize={20}
            lineHeight={"32px"}
            color={"var(--mui-palette-text-secondary)"}
            letterSpacing={"0.15px"}
          >
            Our sign up is simple. We only require your basic company
            information and what type of data storage you want. Our sign up is
            dead simple. We only require your basic company information and what
            type of data storage you want.
          </Typography>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography
            variant={"h4"}
            fontSize={34}
            lineHeight={"42px"}
            fontWeight={300}
          >
            Our process
          </Typography>
          <Typography
            variant={"h6"}
            fontSize={20}
            lineHeight={"32px"}
            color={"var(--mui-palette-text-secondary)"}
            letterSpacing={"0.15px"}
          >
            We support bulk uploading via SQL, integrations with most data
            storage products, or you can use our API. Simply select where you'd
            like to transfer your data and we'll being the process of migrating
            it instantly.
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default WeAre;
