import React from "react";
import {ColorContext} from './ProviderTypes';
import {useColor} from "../../shared/utility";

const ColorProvider = ({children}) => {
  const color = useColor();

  return (
    <ColorContext.Provider value={color}>
      {children}
    </ColorContext.Provider>
  );
};

export default ColorProvider;
