import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Typography } from "@mui/material";
import { useColor } from "../../../../shared/utility";
import Hero from "../../../../assets/image/hero.png";

const Jumbotron = () => {
  const color = useColor();

  return (
    <Grid
      container
      spacing={8}
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
      minHeight={350}
      alignItems={"center"}
    >
      <Grid xs={12} lg={6}>
        <Stack>
          <Typography
            variant={"h2"}
            fontWeight={900}
            fontSize={60}
            lineHeight={"72px"}
            letterSpacing={"-0.5px"}
            color={color.primary.main}
          >
            Contact us
          </Typography>

          <Typography
            variant={"h6"}
            fontWeight={400}
            fontSize={20}
            lineHeight={"32px"}
            letterSpacing={"0.15px"}
            color={color.textSecondary}
          >
            Krealogi Inovasi Digital will make your product look modern and
            professional while saving you precious time.
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} lg={6}>
        <Stack>
          <img src={Hero} alt={"hero"} style={{ aspectRatio: 6 / 4 }} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Jumbotron;
