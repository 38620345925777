import React from "react";
import { Box, styled } from "@mui/system";
import { Stack, Typography } from "@mui/material";

import ourMethodology from "../../../../assets/image/our-methodology.png";
import { useColor } from "../../../../shared/utility";
import { Monitor, PhoneAndroid, Apps } from "@mui/icons-material";

import reactLogo from "../../../../assets/image/logos_react.png";
import muiLogo from "../../../../assets/image/mui-logo.png";
import html5Logo from "../../../../assets/image/html5-logo.png";
import css3Logo from "../../../../assets/image/css3-logo.png";
import sassLogo from "../../../../assets/image/sass.png";
import androidLogo from "../../../../assets/image/android-logo.png";
import iosLogo from "../../../../assets/image/ios-logo.png";
import kotlinLogo from "../../../../assets/image/kotlin-logo.png";
import flutterLogo from "../../../../assets/image/flutter-logo.png";
import waletLogo from "../../../../assets/image/Group 38.png";
import laravelLogo from "../../../../assets/image/laravel-logo.png";
import goLogo from "../../../../assets/image/go-logos.png";
import Grid from "@mui/material/Unstable_Grid2";

const CardIconTechnology = styled(Box)((theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "68px",
  height: "68px",
  background: "#FFFFFF",
  border: "0.4px solid #E5EAF2",
  boxShadow:
    "0px 199px 80px rgba(0, 0, 0, 0.01), 0px 112px 67px rgba(0, 0, 0, 0.05), 0px 50px 50px rgba(0, 0, 0, 0.09)",
  borderRadius: "16px",
}));

const OurDevelopment = () => {
  const color = useColor();

  return (
    <Grid
      container
      spacing={8}
      minHeight="800px"
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
      mb={10}
      alignItems={"flex-start"}
    >
      <Grid xs={12} lg={6}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography fontSize={34} lineHeight={"42px"} fontWeight={300}>
            Our Technology
          </Typography>
          <Typography
            color={color.textSecondary}
            fontSize={16}
            lineHeight={"24px"}
          >
            We create engaging applications with intelligent cloud solutions —
            leveraging our expertise in: geo-fencing, advanced image
            recognition, beacons, automation, customer identification, payment
            gateways and sophisticated analytics.
          </Typography>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              fontWeight={300}
              fontSize={20}
              lineHeight={"32px"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Monitor color={"primary"} />
              Front End Web Platforms
            </Typography>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                gap: 3,
              }}
            >
              <CardIconTechnology>
                <img src={muiLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={reactLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={html5Logo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={css3Logo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={sassLogo} alt={"tech-icon"} />
              </CardIconTechnology>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              fontWeight={300}
              fontSize={20}
              lineHeight={"32px"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <PhoneAndroid color={"primary"} />
              Front End Mobile Platforms
            </Typography>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                gap: 3,
              }}
            >
              <CardIconTechnology>
                <img src={androidLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={iosLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={kotlinLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={flutterLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={waletLogo} alt={"tech-icon"} />
              </CardIconTechnology>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              fontWeight={300}
              fontSize={20}
              lineHeight={"32px"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Apps color={"primary"} />
              Web App & CMS Development
            </Typography>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                gap: 3,
              }}
            >
              <CardIconTechnology>
                <img src={laravelLogo} alt={"tech-icon"} />
              </CardIconTechnology>
              <CardIconTechnology>
                <img src={goLogo} alt={"tech-icon"} />
              </CardIconTechnology>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} lg={6}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography fontSize={34} lineHeight={"42px"} fontWeight={300}>
            Our Methodology
          </Typography>
          <Typography
            color={color.textSecondary}
            fontSize={16}
            lineHeight={"24px"}
          >
            Krealogi Inovasi Digital uses a proprietary SDLC process inspired by
            SCRUM but with added quality controls. We believe in full
            transparency so our team can serve as an extension of your business.
            You will have full access to our tools as well as the ability to
            join: our daily stand-ups, participate in bi-weekly sprint planning
            and offer feedback every two weeks during our product
            demonstrations. <br />
            In addition to this you will receive:
          </Typography>

          <Stack>
            <img
              src={ourMethodology}
              alt={"our-methodology"}
              style={{ aspectRatio: 6 / 4 }}
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OurDevelopment;
