import React from "react";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useColor } from "../../../../shared/utility";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";

const ContactForm = () => {
  const color = useColor();

  return (
    <Stack
      pr={{ xs: "16px", lg: "120px" }}
      pl={{ xs: "16px", lg: "120px" }}
      rowGap={8}
    >
      <Stack textAlign={"center"}>
        <Typography color={color.secondary.main}>CONTACT US</Typography>
        <Typography
          fontSize={34}
          lineHeight={"42px"}
          letterSpacing={"0.25px"}
          variant={"h4"}
        >
          Can't find the answer you need?
        </Typography>
        <Typography
          color={color.text.secondary}
          fontSize={20}
          lineHeight={"32px"}
          variant={"h6"}
        >
          Keep track of what's happening with your data, change permissions, and
          run reports against your data anywhere in the world. Keep track of
          what's happening with your data, change permissions.
        </Typography>
      </Stack>
      <Stack>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid xsOffset={0} xs={6} lg={5} lgOffset={1}>
              <FormControl fullWidth>
                <TextField label={"First Name"} name={"firstName"} />
              </FormControl>
            </Grid>
            <Grid xsOffset={0} xs={6} lg={5}>
              <FormControl fullWidth>
                <TextField label={"Last Name"} name={"lastName"} />
              </FormControl>
            </Grid>

            <Grid xsOffset={0} xs={12} lg={10} lgOffset={1}>
              <FormControl fullWidth>
                <TextField label={"Email"} name={"email"} />
              </FormControl>
            </Grid>

            <Grid xsOffset={0} xs={12} lg={10} lgOffset={1}>
              <FormControl fullWidth>
                <TextField
                  label={"Message"}
                  name={"message"}
                  multiline={true}
                  minRows={4}
                />
              </FormControl>
            </Grid>

            <Grid xsOffset={0} xs={6} lg={5} lgOffset={1}>
              <Typography color={color.text.secondary}>
                We'll get back to you in 1-2 business days.
              </Typography>
            </Grid>
            <Grid xsOffset={4} xs={2} lg={2} lgOffset={3}>
              <FormControl fullWidth>
                <Button variant={"contained"} fullWidth>
                  Submit
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContactForm;
