import React from "react";
import { Stack } from "@mui/material";
import { ContactForm, Jumbotron } from "./components";
import { ContactUs } from "../Home/homeComponents";

const Contact = () => {
  return (
    <Stack rowGap={20}>
      <Stack>
        <Jumbotron />
      </Stack>
      <Stack>
        <ContactForm />
      </Stack>
      <Stack>
        <Stack
          pl={{ xs: "16px", lg: "120px" }}
          pr={{ xs: "16px", lg: "120px" }}
        >
          <ContactUs />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Contact;
