import React from "react";
import { Box } from "@mui/system";
import { Button, Icon, IconButton, Typography } from "@mui/material";
import Logo from "../../../assets/image/Logo.png";
import IGIcon from "../../../assets/icon/ig.png";
import FBIcon from "../../../assets/icon/wa.svg";
import INIcon from "../../../assets/icon/in.png";

const Footer = ({ navigate }) => {
  return (
    <Box
      component={"footer"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        pl: { lg: "120px", xs: 1 },
        pr: { lg: "120px", xs: 1 },
        gap: 1,
        mb: "50px",
        // minHeight: 600
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton>
          <img src={Logo} alt={"logo"} />
        </IconButton>
        <Box sx={{ display: { lg: "inherit", xs: "none" } }}>
          <IconButton
            href="https://instagram.com/krealogi_inovasidigital?igshid=Y2IzZGU1MTFhOQ=="
            target="_blank"
          >
            <Icon>
              <img src={IGIcon} alt={"ig-icon"} />
            </Icon>
          </IconButton>
          <IconButton href="https://wa.me/+6281290326850" target="_blank">
            <Icon>
              <img
                src={FBIcon}
                alt={"fb-icon"}
                style={{ marginTop: "5px", width: "24px" }}
              />
            </Icon>
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/company/krealogi-inovasi-digital/"
            target="_blank"
          >
            <Icon>
              <img src={INIcon} alt={"linkIN-icon"} />
            </Icon>
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "flex-end" },
          alignItems: { xs: "flex-start", lg: "center" },
          gap: { xs: 0, lg: 3 },
          height: { lg: "42px" },
        }}
      >
        <Button color="inherit" onClick={() => navigate("/")}>
          Home
        </Button>
        <Button color="inherit" onClick={() => navigate("/services")}>
          Services
        </Button>
        <Button color="inherit" onClick={() => navigate("/portofolio")}>
          Portfolio
        </Button>
        <Button color="inherit" onClick={() => navigate("/about-us")}>
          About
        </Button>
        <Button color="inherit" onClick={() => navigate("/contact-us")}>
          Contact
        </Button>
      </Box>

      <Box sx={{ display: { lg: "none", xs: "inherit" } }}>
        <IconButton
          href="https://instagram.com/krealogi_inovasidigital?igshid=Y2IzZGU1MTFhOQ=="
          target="_blank"
        >
          <Icon>
            <img src={IGIcon} alt={"ig-icon"} />
          </Icon>
        </IconButton>
        <IconButton href="https://wa.me/+6281290326850" target="_blank">
          <Icon>
            <img
              src={FBIcon}
              alt={"fb-icon"}
              style={{ marginTop: "5px", width: "24px" }}
            />
          </Icon>
        </IconButton>
        <IconButton
          href="https://www.linkedin.com/company/krealogi-inovasi-digital/"
          target="_blank"
        >
          <Icon>
            <img src={INIcon} alt={"linkIN-icon"} />
          </Icon>
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: "center", lg: "flex-end" },
          alignItems: "center",
          gap: 3,
          // height: '42px',
        }}
      >
        <Typography
          color={(theme) => theme.palette.text.secondary}
          fontSize={12}
        >
          © Krealogi Inovasi Digital. 2022, Jakarta. All rights reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
