import React from "react";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";

import AR from "../../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh.png";
import Grid from "@mui/material/Unstable_Grid2";

const Jumbotron = ({ color }) => {
  return (
    <Stack
      position="relative"
      height="400px"
      bgcolor={color.paper}
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
      pt="140px"
      mb={30}
    >
      <Grid
        container
        spacing={8}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid xs={12} lg={6}>
          <Box
            sx={{
              minHeight: "114px",
            }}
          >
            <Typography
              variant={"h3"}
              fontWeight={600}
              lineHeight={"56px"}
              fontSize={"48px"}
            >
              Software Development
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={200}
              lineHeight={"24px"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              Krealogi Inovasi Digital will make your product look modern and
              professional while saving you precious time.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack>
            <img src={AR} alt={"portfolio"} style={{ aspectRatio: 16 / 9 }} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Jumbotron;
