import React, { Fragment } from "react";
import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import ARLogo from "../../../../assets/image/ar-logo.png";
import PPALogo from "../../../../assets/image/ppa-logo.png";
import BapendaLogo from "../../../../assets/image/portfolio/megawe/bapendaLogo.png";
import DimitriLogo from "../../../../assets/image/portfolio/dimitri/dimtriLogo.png";
import AR from "../../../../assets/image/new/Amazing Riyadhoh/amazing_riyadhoh.png";
import PPA from "../../../../assets/image/new/EOA Events/eoa_events.png";
import Bapenda from "../../../../assets/image/new/Presensi Bapenda/megawe.png";
import Dimitri1 from "../../../../assets/image/new/Dimitri/pos_dimitri.png";
import { ArrowForward } from "@mui/icons-material";
import { useColor } from "../../../../shared/utility";
import { useNavigate } from "react-router-dom";

const FeaturedWork = () => {
  const color = useColor();
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={18}
        columnSpacing={8}
        pl={{ xs: 2, lg: "120px" }}
        pr={{ xs: 2, lg: "120px" }}
        justifyContent={"space-between"}
      >
        <Grid xs={12}>
          <Stack gap={3} textAlign={"center"}>
            <Typography
              variant={"h3"}
              fontWeight={600}
              lineHeight={"56px"}
              color={color.primary.main}
            >
              Featured Work
            </Typography>
            <Typography
              variant={"h6"}
              fontWeight={200}
              lineHeight={"32px"}
              color={color.textSecondary}
            >
              Krealogi Inovasi Digital will make your product look modern and
              professional while saving you precious time.
            </Typography>
          </Stack>
        </Grid>

        <Grid xs={12} lg={6}>
          <Stack spacing={3}>
            <img
              src={Bapenda}
              alt={"portfolio"}
              style={{ aspectRatio: 16 / 9 }}
            />
            <Stack spacing={3} alignItems={"center"} direction={"row"}>
              <img src={BapendaLogo} alt={"portfolio-logo"} />
              <Typography
                variant={"h5"}
                fontWeight={500}
                lineHeight={"29px"}
                color={color.primary.main}
              >
                Megawe Bapenda
              </Typography>
            </Stack>
            <Typography variant={"h6"} fontWeight={500} lineHeight={"29px"}>
              Presence System for Bapenda Employees
            </Typography>
            <Button
              variant={"outlined"}
              sx={{ width: "150px", height: "48px" }}
              endIcon={<ArrowForward />}
              onClick={() => navigate("/portofolio/Megawe Bapenda")}
            >
              See Project
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} lg={6}>
          <Stack spacing={3}>
            <img
              src={Dimitri1}
              alt={"portfolio"}
              style={{ aspectRatio: 16 / 9 }}
            />
            <Stack spacing={3} alignItems={"center"} direction={"row"}>
              <img src={DimitriLogo} alt={"portfolio-logo"} />
              <Typography
                variant={"h5"}
                fontWeight={500}
                lineHeight={"29px"}
                color={color.primary.main}
              >
                POS DIMITRI Astra
              </Typography>
            </Stack>
            <Typography variant={"h6"} fontWeight={500} lineHeight={"29px"}>
              Best apps to upgrade your worships
            </Typography>
            <Button
              variant={"outlined"}
              sx={{ width: "150px", height: "48px" }}
              endIcon={<ArrowForward />}
              onClick={() => navigate("/portofolio/POS DIMITRI Astra")}
            >
              See Project
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} lg={6}>
          <Stack spacing={3}>
            <img src={AR} alt={"portfolio"} style={{ aspectRatio: 16 / 9 }} />
            <Stack spacing={3} alignItems={"center"} direction={"row"}>
              <img src={ARLogo} alt={"portfolio-logo"} />
              <Typography
                variant={"h5"}
                fontWeight={500}
                lineHeight={"29px"}
                color={color.primary.main}
              >
                Amazing Riyadhoh
              </Typography>
            </Stack>
            <Typography variant={"h6"} fontWeight={500} lineHeight={"29px"}>
              Best apps to upgrade your worships
            </Typography>
            <Button
              variant={"outlined"}
              sx={{ width: "150px", height: "48px" }}
              endIcon={<ArrowForward />}
              onClick={() => navigate("/portofolio/Amazing Riyadhoh")}
            >
              See Project
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} lg={6}>
          <Stack spacing={3}>
            <img src={PPA} alt={"portfolio"} style={{ aspectRatio: 16 / 9 }} />
            <Stack spacing={3} alignItems={"center"} direction={"row"}>
              <img
                src={PPALogo}
                alt={"portfolio-logo"}
                style={{ aspectRatio: 2 / 1 }}
              />
              <Typography
                variant={"h5"}
                fontWeight={500}
                lineHeight={"29px"}
                color={color.primary.main}
              >
                EOA Events
              </Typography>
            </Stack>
            <Typography variant={"h6"} fontWeight={500} lineHeight={"29px"}>
              E-Ticketing for Event Organizer PPA Institute
            </Typography>
            <Button
              variant={"outlined"}
              sx={{ width: "150px", height: "48px" }}
              endIcon={<ArrowForward />}
              onClick={() => navigate("/portofolio/EOA Events")}
            >
              See Project
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} textAlign={"center"}>
          <Button variant={"outlined"} onClick={() => navigate("/portofolio")}>
            See All Projects
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FeaturedWork;
