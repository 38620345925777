import React from "react";
import { Button, Card, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useColor } from "../../../../../../../shared/utility";
import { ArrowRightAltOutlined } from "@mui/icons-material";

import { data } from "./data";
import { useNavigate } from "react-router-dom";

const SoftwareDevelopment = () => {
  const color = useColor();
  const navigate = useNavigate();
  const theme = useTheme();

  console.log(theme.palette.mode);

  return (
    <Grid container spacing={8}>
      {data.map((portofolio) => {
        return (
          <Grid key={portofolio.title} xs={12} md={6}>
            <Card
              sx={{
                boxShadow:
                  "0px 146px 58px rgba(0, 0, 0, 0.01), 0px 82px 49px rgba(0, 0, 0, 0.03), 0px 37px 37px rgba(0, 0, 0, 0.04), 0px 9px 20px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "16px",
                background:
                  theme.palette.mode === "light"
                    ? color.white
                    : color.background.paper,
              }}
            >
              <Stack direction={"column"} gap={2} p={5}>
                <img
                  src={portofolio.image}
                  alt={portofolio.title}
                  style={{ aspectRatio: 16 / 9 }}
                />
                <Stack gap={2} direction={"row"} alignItems={"center"}>
                  {portofolio.platform.map((platform) => {
                    return (
                      <img
                        src={platform}
                        alt={"logo-bapenda"}
                        width={26}
                        height={28}
                      />
                    );
                  })}
                </Stack>
                <Stack gap={2} direction={"row"} alignItems={"center"}>
                  <img
                    src={portofolio.logo}
                    alt={"logo-bapenda"}
                    width={70}
                    style={{ aspectRatio: 16 / 9 }}
                  />
                  <Typography
                    fontSize={"24px"}
                    fontWeight={900}
                    lineHeight={"29px"}
                    fontStyle={"normal"}
                    color={color.primary.main}
                  >
                    {portofolio.title}
                  </Typography>
                </Stack>
                <Typography
                  fontSize={"20px"}
                  fontWeight={400}
                  lineHeight={"32px"}
                  letterSpacing={"0,15px"}
                >
                  {portofolio.description}
                </Typography>
                <Button
                  variant={"outlined"}
                  color={"primary"}
                  endIcon={<ArrowRightAltOutlined />}
                  onClick={() => navigate(portofolio.url)}
                >
                  See Details
                </Button>
              </Stack>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SoftwareDevelopment;
