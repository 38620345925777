import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const DevelopmentProcess = ({ color }) => {
  return (
    <Grid
      container
      minHeight="400px"
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
      mb={10}
      justifyContent="center"
      alignItems="center"
    >
      <Grid xs={12} lg={4}>
        <Box
          component={"div"}
          sx={{
            minHeight: "144px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant={"body1"}
            fontSize={16}
            lineHeight={"24px"}
            color={color.secondary.main}
          >
            PROCESS
          </Typography>
          <Typography
            variant={"h3"}
            fontWeight={600}
            fontSize={"48px"}
            lineHeight={"56px"}
          >
            Development Process
          </Typography>
        </Box>
      </Grid>

      <Grid xs={12} lg={8}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Typography
            variant={"body1"}
            fontSize={16}
            lineHeight={"24px"}
            color={color.textSecondary}
          >
            Unlike traditional waterfall projects with inflated fixed pricing,
            we use an Agile method of SCRUM to provide you with what you need
            and when you need it. In order to ensure that you only pay for what
            you get using Time & Materials. This provides increased visibility &
            control of the budget, scope & timeline so that you can adapt to:
            shifting product needs, evolving markets, and internal project
            factors.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DevelopmentProcess;
