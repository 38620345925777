import React from "react";
import { Box, styled } from "@mui/system";
import { Typography } from "@mui/material";
import {
  CreditCard,
  Devices,
  DevicesOther,
  EventAvailable,
  Monitor,
  VerifiedOutlined,
} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";

const BoxItem = styled(Box)((theme) => ({
  flexDirection: "column",
  flexBasis: "374px",
  minHeight: "174px",
}));

const BoxIcon = styled(Box)((theme) => ({
  backgroundColor: theme.theme.palette.secondary.main,
  width: "56px",
  height: "56px",
  borderRadius: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const OurSevices = ({ color }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 9,
        pl: { xs: "16px", lg: "120px" },
        pr: { xs: "16px", lg: "120px" },
        minHeight: 800,
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Typography variant={"body2"} color={color.secondary.main}>
          WHAT WE DO?
        </Typography>
        <Typography variant={"h4"} fontWeight={400}>
          We craft beautiful websites and digital products
        </Typography>
        <Typography variant={"h6"} color={(theme) => theme.palette.grey[500]}>
          We design, develop and launch websites and products for startups,
          companies and ourselves.
        </Typography>
      </Box>

      <Grid container spacing={8}>
        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <Monitor sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              Web Design
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We design and develop beautiful, lightning fast, scalable, and
              marketing-focused websites tailored to grow your business.
            </Typography>
          </BoxItem>
        </Grid>

        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <Devices sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              UI/UX Design
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We craft great user centered interfaces for web and mobile apps.
              We ensure to deliver intuitive and seamless experiences.
            </Typography>
          </BoxItem>
        </Grid>

        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <DevicesOther sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              Product Design
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We help you to carefully improve your digital product to deliver a
              great user experience, grow its user base and increase retention.
            </Typography>
          </BoxItem>
        </Grid>

        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <VerifiedOutlined sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              Branding
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We transform businesses into brands through high quality brand
              identity design.
            </Typography>
          </BoxItem>
        </Grid>

        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <EventAvailable sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              Events
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We transform businesses into brands through high quality brand
              identity design.
            </Typography>
          </BoxItem>
        </Grid>

        <Grid xs={12} lg={4}>
          <BoxItem
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <BoxIcon component={"div"}>
              <CreditCard sx={{ color: "#fff" }} />
            </BoxIcon>
            <Typography variant={"h6"} fontWeight={400} lineHeight={"32px"}>
              Procurement
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              color={color.textSecondary}
            >
              We transform businesses into brands through high quality brand
              identity design.
            </Typography>
          </BoxItem>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurSevices;
