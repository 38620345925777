import React from "react";
import { Box, styled } from "@mui/system";
import { Typography } from "@mui/material";

import anggit from "../../../../assets/image/anggit.png";
import rizka from "../../../../assets/image/rizka.png";
import faizal from "../../../../assets/image/faizal.png";
import hilmi from "../../../../assets/image/hilmi.png";
import surya from "../../../../assets/image/surya.png";

const BoxCard = styled(Box)((theme) => ({
  width: "385px",
  height: "144px",
  position: "relative",
}));

const BoxCardDesc = styled(Box)((theme) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "16px 20px 16px 160px",
  position: "absolute",
  width: "100%",
  height: "100px",
  left: "0px",
  top: "44px",
  background: theme.theme.palette.background.paper,
  border: "0.2px solid #E5EAF2",
  boxShadow:
    "0px 86px 52px rgba(0, 0, 0, 0.01), 0px 38px 38px rgba(0, 0, 0, 0.02), 0px 10px 21px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
  borderRadius: "12px",
  zIndex: 1,
}));

const BoxMedia = styled(Box)((theme) => ({
  display: "flex",
  position: "absolute",
  width: "120px",
  height: "120px",
  left: "22.85px",
  top: "0px",
  zIndex: 2,
}));

const OurTeam = () => {
  return (
    <Box
      sx={{
        display: "flex",
        pl: { lg: "120px", xs: 2 },
        pr: { lg: "120px", xs: 2 },
        mt: "30px",
        flexDirection: "column",
        minHeight: 600,
        justifyContent: "center",
        alignItems: "center",
        gap: 6,
      }}
    >
      <Box textAlign={"center"}>
        <Typography
          color={(theme) => theme.palette.secondary.main}
          variant={"body2"}
          fontSize={16}
        >
          OUR TEAM
        </Typography>
        <Typography variant={"h4"} fontWeight={300}>
          Trust the Professionals
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
          justifyContent: "center",
        }}
      >
        <BoxCard>
          <BoxMedia>
            <img
              src={anggit}
              alt={"team"}
              style={{
                borderRadius: "12px",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            />
          </BoxMedia>

          <BoxCardDesc>
            <Typography variant={"h6"} fontWeight={300} lineHeight={"32px"}>
              Anggit Prayogo
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={14}
              lineHeight={"20px"}
              color={(theme) => theme.palette.text.secondary}
            >
              Chairman
            </Typography>
          </BoxCardDesc>
        </BoxCard>

        <BoxCard>
          <BoxMedia>
            <img
              src={rizka}
              alt={"team"}
              style={{
                borderRadius: "12px",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            />
          </BoxMedia>

          <BoxCardDesc>
            <Typography variant={"h6"} fontWeight={300} lineHeight={"32px"}>
              Rizka Tia
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={14}
              lineHeight={"20px"}
              color={(theme) => theme.palette.text.secondary}
            >
              Head of Operations & Finance
            </Typography>
          </BoxCardDesc>
        </BoxCard>

        <BoxCard>
          <BoxMedia>
            <img
              src={faizal}
              alt={"team"}
              style={{
                borderRadius: "12px",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            />
          </BoxMedia>

          <BoxCardDesc>
            <Typography variant={"h6"} fontWeight={300} lineHeight={"32px"}>
              Faizal Afrianto
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={14}
              lineHeight={"20px"}
              color={(theme) => theme.palette.text.secondary}
            >
              Product Designer
            </Typography>
          </BoxCardDesc>
        </BoxCard>

        <BoxCard>
          <BoxMedia>
            <img
              src={hilmi}
              alt={"team"}
              style={{
                borderRadius: "12px",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            />
          </BoxMedia>

          <BoxCardDesc>
            <Typography variant={"h6"} fontWeight={300} lineHeight={"32px"}>
              Hilmi Nico
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={14}
              lineHeight={"20px"}
              color={(theme) => theme.palette.text.secondary}
            >
              Chief Technology Officer
            </Typography>
          </BoxCardDesc>
        </BoxCard>

        <BoxCard>
          <BoxMedia>
            <img
              src={surya}
              alt={"team"}
              style={{
                borderRadius: "12px",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            />
          </BoxMedia>

          <BoxCardDesc>
            <Typography variant={"h6"} fontWeight={300} lineHeight={"32px"}>
              Surya Maulana
            </Typography>
            <Typography
              variant={"body2"}
              fontSize={14}
              lineHeight={"20px"}
              color={(theme) => theme.palette.text.secondary}
            >
              Head of Engineering
            </Typography>
          </BoxCardDesc>
        </BoxCard>
      </Box>
    </Box>
  );
};

export default OurTeam;
