import React from "react";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { MailOutlined, Phone, PinDropOutlined } from "@mui/icons-material";
import { useColor } from "../../../../shared/utility";
import Grid from "@mui/material/Grid";

const ContactUs = () => {
  const color = useColor();

  return (
    <Grid container pl={2} pr={2} spacing={8} mb={16}>
      <Grid item xs={12} lg={8}>
        <Stack>
          <Typography
            variant={"h4"}
            lineHeight={"42px"}
            fontWeight={300}
            fontSize={34}
          >
            Ready to growth your business?
          </Typography>
          <Typography
            variant={"h6"}
            lineHeight={"32px"}
            fontWeight={300}
            fontSize={20}
            color={color.textSecondary}
          >
            It doesn’t have to be a project. Questions or love letters are fine.
            Drop us a line!
          </Typography>
          <Grid container spacing={2} pr={0} pl={0} alignItems={"center"}>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <OutlinedInput
                  placeholder={"Enter Your Email"}
                  sx={{
                    border: "1px solid #E5EAF2",
                    borderRadius: "8px",
                    //minWidth: "496px",
                  }}
                  name="email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant={"contained"}
                sx={{ borderRadius: "8px" }}
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack gap={2}>
          <Typography
            fontWeight={200}
            fontSize={16}
            lineHeight={"24px"}
            color={color.primary.main}
          >
            CONTACT US
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "40px",
                color: "#FFFFFF",
                backgroundColor: color.primary.main,
                width: "32px",
                height: "32px",
              }}
            >
              <Phone fontSize={"12px"} />
            </Box>
            <Stack>
              <Typography fontWeight={200} fontSize={16} lineHeight={"21px"}>
                Phone
              </Typography>
              <Typography
                fontWeight={200}
                fontSize={14}
                lineHeight={"19px"}
                color={color.text.secondary}
              >
                +62-812-9032-6850
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={2}>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "40px",
                color: "#FFFFFF",
                backgroundColor: color.primary.main,
                width: "32px",
                height: "32px",
              }}
            >
              <MailOutlined fontSize={"12px"} />
            </Box>
            <Stack>
              <Typography fontWeight={200} fontSize={16} lineHeight={"21px"}>
                Email
              </Typography>
              <Typography
                fontWeight={200}
                fontSize={14}
                lineHeight={"19px"}
                color={color.text.secondary}
              >
                support@krealogi-digital.com
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={2}>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "40px",
                color: "#FFFFFF",
                backgroundColor: color.primary.main,
                width: "68px",
                height: "32px",
              }}
            >
              <PinDropOutlined fontSize={"12px"} />
            </Box>
            <Stack>
              <Typography fontWeight={200} fontSize={16} lineHeight={"21px"}>
                Address
              </Typography>
              <Typography
                fontWeight={200}
                fontSize={14}
                lineHeight={"19px"}
                color={color.text.secondary}
              >
                Menara Cakrawala Lt. 12, Unit 1205A Jl. M. H. Thamrin No. 9
                Kebon Sirih, Menteng Jakarta Pusat 10340
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
