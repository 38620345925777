import React from "react";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";

import hero from "../../../../assets/image/hero.png";
import Grid from "@mui/material/Unstable_Grid2";

const SoftwareDevelopment = ({ color }) => {
  return (
    <Grid
      container
      spacing={8}
      minHeight={700}
      justifyContent={"space-between"}
      alignItems={"center"}
      pl={{ xs: "16px", lg: "120px" }}
      pr={{ xs: "16px", lg: "120px" }}
    >
      <Grid xs={12} lg={6}>
        <Stack>
          <img src={hero} alt={"hero"} style={{ aspectRatio: 16 / 9 }} />
        </Stack>
      </Grid>
      <Grid xs={12} lg={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant={"body2"}
            fontWeight={200}
            fontSize={16}
            lineHeight={"24px"}
            color={color.secondary.main}
          >
            INTRO
          </Typography>
          <Typography fontSize={48} lineHeight={"56px"} fontWeight={600}>
            Software Development
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={"24px"}
            fontWeight={200}
            color={color.textSecondary}
          >
            Our agile product development teams are structured specifically
            around the needs of your project. This allows us to quickly adapt
            to: shifting product needs, evolving markets, and any internal
            project factors as needed. In return, you receive test builds along
            with a test app presented every 2 weeks.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SoftwareDevelopment;
