import React from "react";
import { Box } from "@mui/system";

import { Typography } from "@mui/material";

import Carousel from "react-material-ui-carousel";
import {
  AssignmentTurnedIn,
  Brush,
  CloudDone,
  DesignServices,
  DeveloperMode,
  EmojiObjects,
} from "@mui/icons-material";
import { useColor } from "../../../../shared/utility";

const DevelopmentProcessDetailsNew = () => {
  const color = useColor();
  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Carousel>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 6,
          }}
        >
          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <EmojiObjects color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Prepare
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Onboarding & Alignment
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  The foundation to building world-class innovative products
                  starts with having a high performing team that is well
                  prepared with access to the correct available documentation
                  and technical environments - allowing us to have clear
                  expectations.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <AssignmentTurnedIn color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Define
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Success Criteria
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  This is the process of defining what you are trying to achieve
                  in a very clear and precise way. When high-level goals turn
                  into business requirements, user journeys, and low fidelity
                  prototypes; it ensures that we have a shared understanding of
                  success.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <DesignServices color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Design
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Customer Intimacy
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  The transition from vision to execution. Where functional
                  specifications are defined, UI wireframes are created, and
                  product backlogs are written. We are able to test the app
                  prototype with real users before a single line of code is
                  written.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 6,
          }}
        >
          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <DeveloperMode color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Develop
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Quality Engineering
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  We use Agile product development implemented with teams
                  structured specifically around the needs of your project. This
                  allows us to adapt to: shifting product needs, evolving
                  markets. Test builds are presented at the end of each sprint,
                  to ensure full transparency.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <Brush color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Polish
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Final Quality Testing
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  It’s best practice to postpone certain activities until after
                  the final feature-development iterations are complete. This
                  gives us time to focus on fine-tuning your: security,
                  performance, final integration testing, app and conduct
                  regression.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component={"div"}
            sx={{
              width: "387px",
              height: "314px",
              background: color.paper,
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                padding: 2,
                display: "flex",
                gap: 2,
              }}
            >
              <CloudDone color={"primary"} sx={{ fontSize: "56px" }} />
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize={20}
                  lineHeight={"32px"}
                  variant={"h6"}
                  fontWeight={200}
                >
                  Go Live
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"body1"}
                  color={color.textSecondary}
                >
                  Final Quality Testing
                </Typography>
                <Typography
                  fontSize={16}
                  lineHeight={"24px"}
                  variant={"h6"}
                  fontWeight={100}
                >
                  The launch is a critical time full of a lot of anticipation &
                  excitement. During this time we: <br />
                  - deploy and monitor your application.
                  <br />
                  - create handover documentation.
                  <br />- your team on the ins and outs of the app so we can
                  scale efficiently.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Carousel>
    </Box>
  );
};

export default DevelopmentProcessDetailsNew;
