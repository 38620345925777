import React, { Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import Hero from "../../../../assets/image/hero.png";
import Grid from "@mui/material/Unstable_Grid2";

const OurStory = () => {
  return (
    <Fragment>
      <Grid container spacing={8}>
        <Grid xs={12} lg={6}>
          <Stack spacing={2}>
            <Typography
              fontSize={16}
              lineHeight={"24px"}
              color={"var(--mui-palette-secondary-main)"}
            >
              Intro
            </Typography>
            <Typography
              variant={"h4"}
              fontWeight={"300"}
              fontSize={"34px"}
              lineHeight={"42px"}
            >
              Our Story
            </Typography>
            <Typography
              variant={"h6"}
              fontSize={20}
              lineHeight={"32px"}
              letterSpacing={"0.15px"}
              color={"var(--mui-palette-text-secondary)"}
            >
              Our focus is always on finding the best people to work with. Our
              bar is high, but you look ready to take on the challenge.We design
              and implement creative solutions to everyday business problems.We
              are a team of creative consultants who help bridge the digital gap
              between companies and their clients with websites that not only
              serve as marketing platforms but also provide solutions to online
              business problems and digital marketing strategies that connect
              you with the ideal client and help create a loyal customer.
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack>
            <img src={Hero} alt={"hero"} style={{ aspectRatio: 16 / 9 }} />
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OurStory;
