import React from "react";
import {createRoot} from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import router from './router';

import App from './components/App';

const container = createRoot(document.getElementById("root"));

container.render(
  <RouterProvider router={router}>
    <App/>
  </RouterProvider>
);
