import React from "react";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline, IconButton, Toolbar, Icon } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import wa from "../../assets/icon/wa.png";
import { AppBarComponent, Footer } from "./comproComponents";
import { ColorProvider } from "../../components/Provider";

const Compro = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  return (
    <CssVarsProvider theme={theme}>
      <ColorProvider>
        <CssBaseline />
        <AppBarComponent navigate={navigate} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>
        </Box>
        <IconButton href="https://wa.me/+6281290326850" target="_blank">
          <Icon>
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                right: "24px",
                cursor: "pointer",
                zIndex: 2,
                width: "66px",
                height: "66px",
              }}
            >
              <img
                src={wa}
                alt="wa"
                style={{
                  height: "66px",
                  marginTop: "20px",
                }}
              />
            </div>
          </Icon>
        </IconButton>

        <Footer navigate={navigate} />
      </ColorProvider>
    </CssVarsProvider>
  );
};

export default Compro;
