import React, { Fragment } from "react";
import { Stack } from "@mui/material";
import { Description, TabPortofolio } from "./components";
import { ContactUs } from "../Home/homeComponents";

const Portofolio = () => {
  // const color = useColor();
  return (
    <Fragment>
      <Stack
        pl={{ lg: "120px", xs: "5px" }}
        pr={{ lg: "120px", xs: "5px" }}
        mt={{ lg: "74" }}
        direction={"column"}
        gap={3}
      >
        <Description />

        <TabPortofolio />

        <ContactUs />
      </Stack>
    </Fragment>
  );
};

export default Portofolio;
