import React, { Fragment } from "react";
import { Box } from "@mui/system";

import {
  Jumbotron,
  SoftwareDevelopment,
  OurDevelopment,
  DevelopmentProcess,
  DevelopmentProcessDetailsNew,
} from "./servicesComponenets";
import { useColor } from "../../../shared/utility";
import { ContactUs } from "../Home/homeComponents";
import { Stack } from "@mui/material";

const Services = () => {
  const color = useColor();
  return (
    <Fragment>
      <Box>
        <Jumbotron color={color} />
      </Box>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <SoftwareDevelopment color={color} />
      </Box>
      <Box>
        <OurDevelopment />
      </Box>
      <Box>
        <DevelopmentProcess color={color} />
      </Box>
      <Box mb={10}>
        <DevelopmentProcessDetailsNew color={color} />
      </Box>
      <Box>
        <Stack
          pl={{ xs: "16px", lg: "120px" }}
          pr={{ xs: "16px", lg: "120px" }}
          pb={10}
        >
          <ContactUs />
        </Stack>
      </Box>
    </Fragment>
  );
};

export default Services;
